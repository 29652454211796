import React from 'react';
import {Image, List, Segment,} from 'semantic-ui-react';
import profileImg1 from "../../assets/foelster-holst.jpg";
import ddg from "../../assets/agp.png";
import componentStyles from "../../templates/default.module.css";

const GreetingsAPD = () => (
    <Segment>
        <Image src={ddg} floated={'right'} size={'small'} className={componentStyles.greetingsImg}/>
        <Image src={profileImg1} size={'medium'} className={componentStyles.greetingsImg}/>
        <p>Sehr geehrte Damen,</p>
        <p>sehr geehrte Herren,</p>
        <p>Hauterkrankungen im Kindes- und Jugendalter sind zwar häufig, aber erfreulicherweise meist gutartig und vorübergehend.</p>
        <p>Kinder und Jugendliche haben wie Erwachsene das Recht auf eine adäquate medizinische Versorgung. Oftmals wird durch deren Hauterkrankung ihre Lebensqualität stark eingeschränkt. Das betrifft sowohl die Krankheitsverläufe der gutartigen als auch bösartigen Hauterkrankungen.
            Unsere medizinische und pflegerische Zuwendung benötigen Kinder und Jugendliche, bei</p>
        <List bulleted>
            <List.Item>häufigen gutartigen Hauterkrankungen, aber schweren Krankheitsverläufen
                (z.B. Neurodermitis, Schuppenflechte, Akne);</List.Item>
            <List.Item>schwerwiegenden Hauterkrankungen
                (z.B. Kawasaki-Syndrom);</List.Item>
            <List.Item>Komplikationen benigner Hauterkrankungen
                (z.B. Eczema herpeticatum bei Neurodermitis);</List.Item>
            <List.Item>Hautkrankheiten, die mit anderen schwerwiegenden Erkrankungen assoziiert sind und somit eine frühzeitige Diagnose und Therapie erfordern
                (z. B. Xanthogranulome, die in Verbindung mit der Neurofibromatose mit einem erhöhten Risiko der Entwicklung einer Leukämie verbunden sind).</List.Item>
        </List>
        <p>Die Grundvoraussetzungen für diese medizinische Betreuung sind in Deutschland für hautkranke Kinder und Jugendliche im Vergleich zur Betreuung der hautkranken Erwachsenen mangelhaft.</p>
        <p>Eine adäquate medizinische Versorgung erfordert spezielle Kenntnisse im Fach „Pädiatrische Dermatologie“, über die in Deutschland bisher nur sehr wenige Haut- und Kinderärzte verfügen. Eine entsprechende Ausbildung gibt es, wie es in anderen Ländern längst etabliert, in Deutschland bisher nicht. </p>
        <p>Dies hat erschreckende Konsequenzen für die ambulante und stationäre Betreuung der Betroffenen. Das können vor allem Eltern von hautkranken Kindern und Jugendlichen bestätigen, die in Deutschland auf der Suche nach Ärzten und Kliniken sind, um eine rasche Diagnose und erfolgreiche Hilfe zu bekommen.</p>
        <p>Es ist nicht akzeptabel, dass aus finanziellen Gründen in der Industrienation Deutschland eine Gewährleistung einer adäquaten dermatologischen Arzneiversorgung der hautkranken Kinder und Jugendlichen ab dem 12. Lebensjahr nicht gegeben ist. So werden beispielsweise die Kosten der wichtigsten Säule der Neurodermitis-Therapie, die Substitution mit fetthaltigen und wasserbindenden Substanzen, nach dem 12. Lebensjahr von den Gesetzlichen Krankenkassen nicht mehr getragen. Es wird völlig außer Acht gelassen, dass es sich nicht um eine „Pflege“, sondern vielmehr um die medizinische Substitution von fetthaltigen und hydratisierenden Substanzen handelt, die der Haut eines Kindes oder Jugendlichen mit Neurodermitis fehlen.</p>
        <p>Darüber hinaus sind Defizite sowohl in der Grundlagen- als auch in der klinischen Forschung zu verzeichnen.</p>
        <p>Die Pädiatrische Dermatologie betreffend liegt also noch Vieles im Argen. </p>
        <p>Umso lobenswerter ist es, dass sich die Hautärztin Frau Dr. Inselkammer aus München diesen Problemen mit der Gründung der Deutschen Stiftung Kinderdermatologie widmen will.</p>
        <p>Und das mit dem folgenden 8-Punkte-Förderprogramm zur Verbesserung der Versorgung hautkranker Kinder und Jugendlicher:</p>
        <List ordered>
            <List.Item>Unterstützung hautkranker Kinder und Jugendliche</List.Item>
            <List.Item>Bildung von Zentren</List.Item>
            <List.Item>Unterstützung bestehender Einrichtungen zur Behandlung von hautkranken Kindern und Jugendlichen</List.Item>
            <List.Item>Förderung der Patientenschulung</List.Item>
            <List.Item>Förderung von Wissenschaft, Forschung und Lehre sowie Aufklärung der Öffentlichkeit im Bereich der Pädiatrischen Dermatologie</List.Item>
            <List.Item>Preisvergabe für hervorragende Arbeit in Forschung, Praxis und Klinik</List.Item>
            <List.Item>Stipendienprogramm für den Nachwuchs</List.Item>
            <List.Item>Fortbildung und Ausbildung der Ärzte und des Pflegepersonals</List.Item>
        </List>
        <p>Ich bin mir sicher, dass die Deutsche Stiftung Kinderdermatologie mit diesem beherzten 8-Punkte-Förderprogramm einer optimalen Versorgung von hautkranken Kindern und Jugendlichen einen großen Schritt näher kommen wird.</p>
        <p>Auf ein gutes Gelingen!</p>
        <p>Prof. Dr. med. Regina Fölster-Holst</p>
    </Segment>

);

export default GreetingsAPD;